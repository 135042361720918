import React from "react";
import Layout from "../../../Layout";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../page-style.scss";

const data = (function () {
  let imgArray = [];
  for (let i = 1; i <= 43; i++) {
    let imgUrl = `https://chinmayavvdelhi.ac.in/assets/images/calendar/09-jan-24-1/pic${i}.JPG`;
    imgArray.push(imgUrl);
  }

  return imgArray;
})();

export default function PrizeDestributionSenior2023() {
  const settings = {
    infinite: true,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  function ImageSlider() {
    return (
      <div className="calendar-page__slider">
        <Slider {...settings}>
          {data.map((item, i) => (
            <div className="calendar-page__images__slide">
              <div className="calendar-page__images__card">
                <img alt="" src={item}></img>
              </div>
              <br />
              <center>
                <h3>{item.titleSubHead}</h3>
              </center>
            </div>
          ))}
        </Slider>
      </div>
    );
  }

  return (
    <Layout>
      {/* <SEO
        title="School Gallery | Chinmaya Vidyalaya Vasant Vihar"
        description="Explore photos and videos of the campus and student activities  at the Vidyalaya for a holistic view of student life."
      /> */}

      <div className="calendar-page">
        <div className="goback">
          <a href="/school-events/">
            <i className="fa-solid fa-arrow-left"></i>Go Back
          </a>
        </div>
        <h1 className="heading">
          Senior Class - Annual Prize Distribution Ceremony
        </h1>

        <p>
          The Senior Wing of Chinmaya Vidyala recently held a glittering Award
          Ceremony, Aaj Ka Arjun.
          <br />
          <br />
          Shri Vikram K Porwal, Deputy Commissioner of Police graced the
          occasion as the Chief Guest.
          <br />
          <br />
          He acknowledged the achievements of the students, applauding their
          dedication, hard work, multifaceted talents, and outstanding
          performance in both academic and co-curricular realms. 
          <br />
          <br />
          Shri Porwal was greatly inspired by Swami Chinmayananda and emphasised
          the importance of integrating sports, values, and spiritual upliftment
          in nurturing well-rounded individuals.
          <br />
          <br />
          The event celebrated the teachers who have completed 15 and 25 years
          of exceptional service. Their dedication has played a pivotal role in
          shaping the academic and cultural landscape of the Vidyalaya.
          <br />
          <br />
          The school orchestra set the stage aglow with harmonious melodies
          while a captivating fusion dance created a visual delight.
          <br />
          <br />
          The programme's highlight was the gripping play, 'Aaj Ka Arjun’ which
          delved into the timeless narrative of Arjuna’s moral dilemma and
          juxtaposed it with the issues faced by the youth today. It was a
          thought-provoking exploration of morality and decision-making.
          <br />
          <br />A seamless integration of dance within the play added another
          dimension to the performance.
        </p>
        <section className="calendar-page__videosection">
          <ImageSlider />
        </section>
      </div>
    </Layout>
  );
}
